<template>
    <div id="web-map">
        <baidu-map class="bm-view" :ak="ak" center="广州" :zoom="3">
            <bml-marker-clusterer :averageCenter="true">
                <bm-marker :key="index" v-for="marker of markers" :position="{lng: marker.lng, lat: marker.lat}"></bm-marker>
            </bml-marker-clusterer>
        </baidu-map>
    </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import {BmlMarkerClusterer,BmMarker} from 'vue-baidu-map';
export default {
    name: 'webmap',
    components: {
        BaiduMap,
        BmlMarkerClusterer,
        BmMarker
    },
    data(){
        return{
            ak:'ub1zKXpAb3A6dlIl9T3RxW2nTfAqjaGA',
            markers:[
                {lng:113,lat:23},
                {lng:103,lat:13},
                {lng:93,lat:22},
            ]
        }
    },
    method:{}
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
}
html,body{
    margin: 0;
    padding: 0;
}
#web-map{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.bm-view{
    width: 100%;
    height: 100%;
}
</style>
